<script setup>
import CardComponent from './CardComponent.vue';

const data=[{
    title: "Rental-Accommodation",
    tags: ["#webdev", "#thesis"],
    link: "https://github.com/Button99/Rental-Accommodation"
    },
    {
    title: "PurchaseList",
    tags: ["#webdev", "purchase", "products"],
    link: "https://purchase-list.website/"
    },
    {
    title: "Multi-Bot",
    tags: ["#bot", "#python", "#discord"],
    link: "https://github.com/Button99/Multi-Bot"
    },
    {
    title: "CarRental",
    tags: ["#csharp", "#cars", "management"],
    link: "https://github.com/Button99/CarRental"
    },
];

</script>

<template>
    <p class="text-4xl"> My projects </p> <br /> <br />
    <div class="flex justify-center flex-wrap">
        <CardComponent v-bind:data="data"/>
    </div>
</template>