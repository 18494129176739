<script setup>
import CardComponent from './CardComponent.vue';
const data= [{
    title: "Reflecting my 4 month journey as Junior Software Engineer",
    tags: ["#webdev", "#career", "#beginners"],
    link: "https://dev.to/button99/reflecting-my-4-month-journey-as-junior-software-engineer-9p2"
}];

</script>

<template>
    <p class="text-4xl"> My recent posts </p> <br /> <br />
    <div class="flex justify-center flex-wrap">
        <CardComponent v-bind:data="data"/>
    </div>
</template>