<script setup>
</script>

<template>
    <h1 class="text-4xl">Work</h1><br/>

    <hr class="my-8 h-1 border-0 bg-[#f0fdfa] md:my-10"/>

    <h2 class="text-2xl"> <span class="underline"> 04/2022 - 09/2022: </span> Network IP/PBX Technician - OTE Group of Companies (HTO) Internship </h2>
        <p class="text-xl my-8 mx-auto">
            Description:
            Managing projects in Cosmote, Greece’s largest telecommunication company. More specifically I ‘ve been completing tasks of: <br />
            <ul class="pl-5 mt-2 text-xl">
                <li>Install, repair, maintain analog/digital/SIP phone systems. </li>
                <li>Program telephony devices.</li> 
                <li>Troubleshooting telephony devices. </li>
                <li>Testing software. </li>
                <li>Installing new equipment on Call Centers. </li> 
            </ul>
        </p>
    <br />
    <hr class="my-8 w-20 h-1 border-0 bg-[#f0fdfa] mx-auto md:my-10 justify-center items-center"/>

    <h2 class="text-2xl"> <span class="underline">05/2023 - Now:</span> Associate PHP Developer - Qualco</h2>
        <p class="text-xl my-8 mx-auto">Description:
            I work as a PHP Developer, outsourcing my services to Victus Networks. <br />
            My responsibilities include designing and implementing corporate applications, <br />
            with expertise in PHP (Laravel) for backend development and Vue.js (JavaScript) for front-end development.
        </p>
</template>