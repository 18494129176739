<script setup>
import { ref, defineEmits } from 'vue';

const comp= ref('Home');
const emit = defineEmits(['update:comp']);

function changeComp(value) {
    comp.value= value;
    emit('update:comp', comp.value);
}
</script>

<template>
<div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
    <nav class="md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center flex flex-wrap justify-center items-center space-x-7">
        <button class="block py-2 pl-3 md:text-4xl text-2xl pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent hover:scale-105 transform transition-transform duration-300" @click="changeComp('Home')">Home</button>
        <button class="block py-2 pl-3 md:text-4xl text-2xl pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent hover:scale-105 transform transition-transform duration-300" @click="changeComp('Posts')">Posts</button>
        <button class="block py-2 pl-3 md:text-4xl text-2xl pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent hover:scale-105 transform transition-transform duration-300" @click="changeComp('Projects')">Projects</button>
        <button class="block py-2 pl-3 md:text-4xl text-2xl pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent hover:scale-105 transform transition-transform duration-300" @click="changeComp('Contact')">Contact</button>
        <button class="block py-2 pl-3 md:text-4xl text-2xl pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent hover:scale-105 transform transition-transform duration-300" @click="changeComp('WorkExp')">Experience</button>
    </nav>
</div>
</template>