<script setup>
</script>
<template>
    <h1 class="text-4xl">Hello <span class="wave">👋</span>!</h1> <br/>
    <p class="text-2xl">
        My name is Christos Koumpis and I am a PHP Developer at <a href="https://www.qualco.eu/" class="underline">Qualco</a>! <br />
        I created this space to share my thoughts, experiences and skills! <br />
        Feel free to reach out if you have any questions. <br />
        Hope you have fun exploring my website 🎉! 
    </p>
</template>

<style>
.wave {
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;        /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(12.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
   20% { transform: rotate(-4.0deg) }
   30% { transform: rotate(12.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(8.0deg) }
   60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
}
</style>