<template>
  <NavbarComponent :comp="comp" @update:comp="updateComp"/>
  <br/>
  <br/>
  <component :is="currentComponent"/>
</template>

<script>
import IndexComponent from './components/IndexComponent.vue';
import NavbarComponent from './components/NavbarComponent.vue';
import ContactComponent from './components/ContactComponent.vue';
import PostComponent from './components/PostComponent.vue';
import ProjectComponent from './components/ProjectComponent.vue';
import WorkExpComponent from './components/WorkExpComponent.vue';
export default {
  showComponent: 'Home',
  name: 'App',
  components: {
    IndexComponent,
    NavbarComponent,
    ContactComponent,
    PostComponent,
    ProjectComponent,
    WorkExpComponent
  },
  data () {
    return {
      comp: 'Home'
    }
  },
  computed: {
    currentComponent() {
      if(this.comp === 'Home') {
        return 'IndexComponent';
      } else if(this.comp === 'Contact') {
        return 'ContactComponent';
      } else if(this.comp === 'Projects') {
        return 'ProjectComponent';
      } else if(this.comp === 'Posts') {
        return 'PostComponent';
      } else if(this.comp === 'WorkExp') {
        return 'WorkExpComponent';
      }
      return 'IndexComponent'
    }
  },
  methods: {
    updateComp(value) {
      console.log(value);
      this.comp= value;
    } 
  }
}
</script>

<style>
#app {
  font-family: Oswald;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #f0fdfa;
  margin-top: 60px;
}
</style>
